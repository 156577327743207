module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Colby Miller","short_name":"Colby Miller","description":"Experienced software engineer with a passion for full stack web and mobile development!","lang":"en","start_url":"/","background_color":"#4c4c4e","theme_color":"#fff","display":"standalone","icon":"src/assets/site_icon.png","crossOrigin":"use-credentials"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-133632870-1","head":true},
    }]
